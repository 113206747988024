import "./App.css";
import Header from "./components";

function App() {
  return (
    <div className="flex h-screen">
      <div className="w-full flex-grow bg-background">
        <div className="App h-full max-w-[756px] mx-auto my-12">
          <Header />
        </div>
      </div>
    </div>
  );
}

export default App;
