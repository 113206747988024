import React from "react";

function Header() {
  return (
    <header className="flex">
      {/*<div>
        <a href="/">
          <img
            className="w-24 h-28 rounded-md object-cover"
            src="//images.squarespace-cdn.com/content/v1/57519b954d088e7919ec398e/1588244051155-E9HXHMOR1N7Y65JHY7B3/0.jpg?format=1000w"
            alt="Dmytro Sheiko"
          />
        </a>
        <p className="text-sm">Dmytro Sheiko</p>
      </div>
      <nav className="main-nav flex-1 flex items-center justify-end">
        <ul className="flex space-x-4">
          <li className="hover:text-blue-600">
            <a href="/">Portfolio</a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/dmitrysheiko"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-600"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="https://github.com/sheyko-d"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-600"
            >
              GitHub
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:text-blue-600">
              Contact
            </a>
          </li>
        </ul>
      </nav>*/}
    </header>
  );
}

export default Header;
